import { css } from 'styled-components';

export default css`
  .cur-pointer {
    cursor: pointer;
  }
  .cur-not-allowed {
    cursor: not-allowed;
  }

  .pointer-events-none {
    pointer-events: none;
  }
`;
