import { css } from 'styled-components';
import body from './body';
import form from './form';
import shadowClasses from './shadowClasses';
import input from './input';
import link from './link';
import cursor from './cursor';
import font from './font';

export default css`
  ${body}
  ${form}
  ${shadowClasses}
  ${input}
  ${link}
  ${cursor}
  ${font}
`;
