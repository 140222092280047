import React from 'react';
import { createGlobalStyle } from 'styled-components';
import tsfont from '../../tsfont.json';
import Head from 'next/head';
import {
  APPLE_TOUCH_ICON_LINK,
  FAVICON_32x32_LINK,
  FAVICON_16x16_LINK,
  FAVICON_LINK
} from 'helpers/urls';
import shell from 'styles/shell';
import { fontVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';
import { minWidth } from 'helpers/buildMediaQueries';

const defaultFontFamily =
  'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, ' +
  'Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif';

const GlobalStyle = createGlobalStyle<{ noScrolling: boolean }>`
  html {
      scroll-padding-top: 160px;
      
      ${minWidth('md', 'scroll-padding-top: 180px;')};
  }

  body {
    font-family: ${defaultFontFamily};
    margin: 0;
    line-height: 1.23;
  }

  html, body {
    scroll-behavior: smooth;
  }
  
  ${(props: { noScrolling: boolean }) =>
    props.noScrolling &&
    `
    html {
      overflow: hidden;
      touch-action: none;
    }
    
    body {
      pointer-events: none;
    }
  `};

  button, input {
    &:active, &:focus {
      outline: none;
    }
  }  
  
  ${tsfont.templateOptions.baseSelector}:before {
    font-size: ${fontVariables.SIZE_ICON};
    line-height: ${fontVariables.LINE_HEIGHT_M};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  * {
    box-sizing: border-box;
  }

  ${shell}
`;
/*
Warning:
Please do not move buildRobotoFontCSS into createGlobalStyle!
Otherwise, in Chrome, fonts will be reloaded when a tooltip opens. (Safari does not seem to have this problem.)
Issue can be found here: https://github.com/styled-components/styled-components/issues/1593
Workaround has been described here: https://stackoverflow.com/questions/42675725/isolated-styled-components-with-font-face/50174102#50174102
*/

const BasicLayout = ({
  noScrolling,
  children
}: {
  noScrolling: boolean;
  children: any;
}) => (
  // Do not buildRobotoFontCSS here!
  // Otherwise, SEO related HTML (e.g. meta tags) will come too late.
  <>
    <Head>
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="180x180"
        href={APPLE_TOUCH_ICON_LINK}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={FAVICON_32x32_LINK}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={FAVICON_16x16_LINK}
      />
      <link rel="shortcut icon" type="image/x-icon" href={FAVICON_LINK} />
    </Head>
    <GlobalStyle noScrolling={noScrolling} />
    {children}
  </>
);

export default BasicLayout;
