import { css } from 'styled-components';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export default css`
  a,
  a:visited,
  .text-link {
    text-decoration: none;
  }

  a,
  .text-link {
    color: ${colorsVariables.BLUE_700};
    transition: color 0.2s;
    cursor: pointer;

    &:visited {
      color: ${colorsVariables.BLUE_700};
    }

    &:hover {
      color: ${colorsVariables.BLUE_600};
      text-decoration: underline;
    }

    &.link-with-icon:hover {
      text-decoration: none;

      span:first-child {
        text-decoration: underline;
      }
    }
  }
`;
