import { css } from 'styled-components';
import {
  colorsVariables,
  fontVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

export default css`
  .input {
    min-height: 40px;
    font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
    font-size: ${fontVariables.SIZE_TEXT};
    -webkit-appearance: none;

    color: ${colorsVariables.BLACK};

    &:not(:disabled) {
      border: 1px ${colorsVariables.GRAY_200} solid;
    }
    &:disabled {
      border: none;
      color: ${colorsVariables.BLACK};
    }

    outline: none;
    border-radius: 4px;
    box-sizing: border-box;

    width: 100%;
    padding-left: 9px;
    padding-top: 12px;

    &:-webkit-autofill {
      background-color: ${colorsVariables.WHITE} !important;
      color: ${colorsVariables.BLACK} !important;
    }

    &:focus,
    &.force-focus,
    &.focus {
      outline: none;
      border: 1px ${colorsVariables.CURACAO} solid;
    }
    &[type='date'].empty {
      &::-webkit-datetime-edit {
        color: transparent;
      }
    }

    &:focus,
    &:not(.empty),
    &.sticky-top,
    &.focus {
      ~ label {
        top: 3px;
        font-size: 12px;
      }
    }

    &:-webkit-autofill ~ label {
      top: 3px;
      font-size: 12px;
    }

    &[class^='invalid-'],
    &[class*=' invalid-'] {
      border: 1px ${colorsVariables.RED_500} solid;
      color: ${colorsVariables.RED_500};
    }

    &.with-action {
      padding-right: 100px;
    }
  }

  .input ~ label {
    color: ${colorsVariables.GRAY_500};
    font-size: ${fontVariables.SIZE_TEXT};
    font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};

    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
`;
