import { css } from 'styled-components';
import { fontVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export default css`
  body {
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
    line-height: 1.5;
    word-break: break-word;
  }
`;
