import { breakpointsVariables } from 'styles/breakpoints.variables';

export const minWidth = (
  query: keyof typeof breakpointsVariables,
  content: string
): string => {
  if (!breakpointsVariables || !breakpointsVariables[query].min) {
    return content;
  }

  return `@media screen and (min-width: ${breakpointsVariables[query].min}) { ${content} }`;
};

export const onlyWidth = (
  query: keyof typeof breakpointsVariables,
  content: string
): string => {
  if (
    !breakpointsVariables[query] ||
    !breakpointsVariables[query].min ||
    !breakpointsVariables[query].max
  ) {
    return content;
  }

  return `@media screen and (min-width: ${breakpointsVariables[query].min}) and (max-width: ${breakpointsVariables[query].max}) { ${content} }`;
};
