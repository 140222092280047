import Head from 'next/head';
import { FormattedMessage, useIntl } from 'react-intl';
import { DefaultPage } from 'styles/DefaultPage.styles';
import {
  Footer,
  buildRobotoFontCSS,
  Context,
  NavigationV2
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { LocalesAllowedValues } from 'models/Locales';
import { googleTagManager } from 'helpers/urls';
import helios from 'fonts/helios';
import {
  MainContentWrapperStyled,
  NotFoundText,
  CompanySearchLink,
  NotFoundHeadline,
  NotFoundMainBox
} from './NotFoundPage.styles';
import { AppComponentProps } from 'models/AppComponentProps';
import BasicLayout from 'layouts/BasicLayout';
import columnSizes from 'styles/column-sizes.variables';
import NotFoundPageIllustration from 'components/NotFoundPage/NotFoundPageIllustration';

export default function NotFoundPage({
  mobileMenuOpen,
  setMobileMenuOpen
}: AppComponentProps) {
  const { formatMessage, messages } = useIntl();
  const translations = messages as LocalesAllowedValues;
  const companySearchLink = formatMessage({
    id: 'COMPANYPROFILES.PROFILE_OFFLINE.CTA.LINK'
  });

  return (
    <BasicLayout noScrolling={mobileMenuOpen}>
      <DefaultPage>
        <Head>
          <title>
            {formatMessage({ id: 'COMPANYPROFILES.PROFILE_OFFLINE.HEADLINE' })}
          </title>
          <meta name="robots" content="noindex, follow" />
          <meta
            name="description"
            content={formatMessage({
              id: 'COMPANYPROFILES.PROFILE_OFFLINE.HEADLINE'
            })}
          />
          <link rel="preconnect" href={googleTagManager} />
          <style dangerouslySetInnerHTML={{ __html: buildRobotoFontCSS() }} />
          <style dangerouslySetInnerHTML={{ __html: helios }} />
        </Head>
        <NavigationV2
          hasSearchBar
          searchBarVisibleFromPx={-1}
          activeFilters={{}}
          translations={translations}
          mobileMenuHandler={{
            mobileMenuOpen,
            setMobileMenuOpen
          }}
          searchPlaceholder={formatMessage({ id: 'SEARCH_PLACEHOLDER' })}
          customHeaderWidths={columnSizes}
        />
        <MainContentWrapperStyled>
          <NotFoundMainBox noMargin>
            <NotFoundPageIllustration />
            <NotFoundHeadline as="h1">
              {formatMessage({
                id: 'COMPANYPROFILES.PROFILE_OFFLINE.HEADLINE'
              })}
            </NotFoundHeadline>
            <NotFoundText>
              {formatMessage({ id: 'COMPANYPROFILES.PROFILE_OFFLINE.TEXT' })}
            </NotFoundText>
            <div>
              <CompanySearchLink
                href={companySearchLink}
                context={Context.Primary}
              >
                <FormattedMessage
                  id="COMPANYPROFILES.PROFILE_OFFLINE.CALL_TO_ACTION"
                  tagName="span"
                />
              </CompanySearchLink>
            </div>
          </NotFoundMainBox>
        </MainContentWrapperStyled>
        <Footer
          translations={translations}
          footerStructure={translations && translations['FOOTER_STRUCTURE']}
          footerImprintStructure={
            translations && translations['FOOTER_IMPRINT_STRUCTURE']
          }
        />
      </DefaultPage>
    </BasicLayout>
  );
}
