import styled from 'styled-components';
import columnSizes from './column-sizes.variables';
import { minWidth as minWidthGenerator } from 'helpers/buildMediaQueries';
import { gridGapInPixels } from 'styles/Page.styles';

const gridTemplateMediaQueries = columnSizes
  .map(({ minWidth, mainContainerWidth, asideContainerWidth }) =>
    minWidthGenerator(
      minWidth,
      `grid-template-areas: 'company-header company-header company-header' '. main-content-wrapper .' 'footer footer footer';
      grid-template-columns: 1fr ${mainContainerWidth}px ${
        asideContainerWidth &&
        asideContainerWidth + gridGapInPixels.desktop + 'px'
      } 1fr;`
    )
  )
  .join('');

export const DefaultPage = styled.div`
  min-height: 100vh;
  display: grid;
  background-color: #f8f8fa;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: 'main-content-wrapper' 'footer';

  ${gridTemplateMediaQueries};

  overflow-x: hidden;
`;
