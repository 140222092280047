import styled from 'styled-components';
import { minWidth } from 'helpers/buildMediaQueries';
import notFoundPage from 'components/NotFoundPage/not-found-illustration.svg';

const NotFoundPageIllustrationStyled = styled.div`
  width: 102px;
  height: 102px;

  ${minWidth(
    'xxl',
    `
      margin-bottom: 16px;
      width: 140px;
      height: 140px;
  `
  )};

  svg {
    height: 100%;
    width: auto;
  }
`;

export default function NotFoundPageIllustration() {
  return (
    <NotFoundPageIllustrationStyled
      dangerouslySetInnerHTML={{ __html: notFoundPage }}
    />
  );
}
