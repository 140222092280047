import styled from 'styled-components';
import { minWidth as minWidthGenerator } from 'helpers/buildMediaQueries';
import { gutterVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export const gridGapInPixels = {
  mobile: 24,
  desktop: 32
};

export const MainContentWrapper = styled.div`
  display: grid;
  grid-gap: ${gridGapInPixels.mobile}px;
  grid-area: 2 / 1 / 3 / 4;
  padding: 0 ${gutterVariables.GUTTER};
  margin-bottom: 64px;

  ${minWidthGenerator('sm', 'grid-area: 2 / 2 / 3 / 3;')};
  ${minWidthGenerator('md', 'padding: 0;')};
  box-sizing: border-box;

  ${minWidthGenerator(
    'xxl',
    `
    width: 100%; 
    grid-gap: ${gridGapInPixels.desktop}px;
  `
  )}
`;

export const EmptyProfileMainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
