import FontEot from './ts-profiles-font.eot';
import FontWoff from './ts-profiles-font.woff';
import FontWoff2 from './ts-profiles-font.woff2';

export default String.raw`
@font-face {
  font-family: "ts-profiles-font";
  src: url(${FontEot}) format("embedded-opentype"),
       url(${FontWoff2}) format("woff2"),
       url(${FontWoff}) format("woff");
}

.hls {
	line-height: 1;
}

.hls:before {
	font-family: ts-profiles-font !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.hls-star-filled:before {
	content: "\f101";
}
.hls-feedback-check-circle:before {
	content: "\f102";
}
.hls-icon-feedback-info-circle-outline:before {
	content: "\f103";
}
.hls-icon-feedback-info-circle:before {
	content: "\f104";
}
.hls-icon-action-search:before {
	content: "\f105";
}
.hls-icon-action-select-date:before {
	content: "\f106";
}
.hls-icon-action-report:before {
	content: "\f107";
}
.hls-icon-label-location-pin:before {
	content: "\f108";
}
.hls-icon-feedback-email-alternative:before {
	content: "\f109";
}
.hls-icon-label-phone-call:before {
	content: "\f10a";
}
.hls-icon-arrow-chevron-down:before {
	content: "\f10b";
}
.hls-icon-arrow-chevron-up:before {
	content: "\f10c";
}
.hls-icon-arrow-chevron-right:before {
	content: "\f10d";
}
.hls-icon-arrow-chevron-left:before {
	content: "\f10e";
}
.hls-icon-arrow-chevron-right-double:before {
	content: "\f10f";
}
.hls-icon-arrow-chevron-left-double:before {
	content: "\f110";
}
.hls-icon-arrow-right:before {
	content: "\f111";
}
.hls-icon-feedback-verified:before {
	content: "\f112";
}
.hls-icon-label-b2c:before {
	content: "\f113";
}
.hls-icon-feedback-check:before {
	content: "\f114";
}
.hls-icon-user-user-circle:before {
	content: "\f115";
}
.hls-icon-feature-navigation-helpcenter:before {
	content: "\f116";
}
.hls-icon-feedback-warning-sign:before {
	content: "\f117";
}
.hls-icon-action-hamburger-menu:before {
	content: "\f118";
}
.hls-icon-action-open-in-new-tab:before {
	content: "\f119";
}
.hls-icon-action-dismiss:before {
	content: "\f11a";
}
.hls-icon-trusted-shops-product-buyer-protection:before {
	content: "\f11b";
}
.hls-icon-trusted-shops-product-certification:before {
	content: "\f11c";
}
.hls-icon-label-b2b:before {
	content: "\f11d";
}
.hls-icon-feature-navigation-support:before {
	content: "\f11e";
}
.hls-icon-action-favorite-with-star:before {
	content: "\f11f";
}
`;
