export const breakpointsVariables = {
  xs: {
    min: '0px',
    max: '575px',
    maxScreeSize: 575
  },
  sm: {
    min: '576px',
    max: '767px'
  },
  md: {
    min: '768px',
    max: '991px'
  },
  lg: {
    min: '992px',
    max: '1023px'
  },
  xl: {
    min: '1024px',
    max: '1439px'
  },
  xxl: {
    min: '1440px',
    max: '100%'
  }
};
