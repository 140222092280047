import styled from 'styled-components';
import { colorsVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

interface MainBoxProps {
  noMargin?: boolean;
  noShadow?: boolean;
}

export const MainBox = styled.div<MainBoxProps>`
  background-color: ${colorsVariables.WHITE};
  box-shadow: 0 1px 5px 0 rgba(24, 39, 75, 0.08);
  border-radius: 8px;
  padding: 20px;

  ${(props) => props.noMargin && 'margin: 0;'}
  ${(props) => props.noShadow && 'box-shadow: none;'}
`;
