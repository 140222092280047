import { breakpointsVariables } from './breakpoints.variables';
import { gridGapInPixels } from 'styles/Page.styles';

export const columnSizes: {
  minWidth: keyof typeof breakpointsVariables;
  mainContainerWidth: number;
  asideContainerWidth: number;
  containerWidth: number;
}[] = [
  {
    minWidth: 'md',
    mainContainerWidth: 634,
    asideContainerWidth: 0,
    containerWidth: 634
  },
  {
    minWidth: 'lg',
    mainContainerWidth: 754,
    asideContainerWidth: 0,
    containerWidth: 754
  },
  {
    minWidth: 'xl',
    mainContainerWidth: 754,
    asideContainerWidth: 0,
    containerWidth: 754
  },
  {
    minWidth: 'xxl',
    mainContainerWidth: 736,
    asideContainerWidth: 352,
    containerWidth: 736 + 352 + gridGapInPixels.desktop
  }
];

export default columnSizes;
