import { css } from 'styled-components';
import { shadowsVariables } from 'styles/shadows.variables';

export default css`
  .shadow-1 {
    box-shadow: ${shadowsVariables.shadow1};
  }
  .shadow-2 {
    box-shadow: ${shadowsVariables.shadow2};
  }
`;
