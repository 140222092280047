import styled from 'styled-components';
import { MainContentWrapper } from 'styles/Page.styles';
import {
  LinkAsButton,
  fontVariables,
  gutterVariables,
  Heading,
  colorsVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { MainBox } from 'components/MainBox/MainBox';
import { minWidth } from 'helpers/buildMediaQueries';

export const MainContentWrapperStyled = styled(MainContentWrapper)`
  grid-area: 1 / 2 / 1 / 4;
  grid-template-rows: auto auto;
  padding: 0 ${gutterVariables.GUTTER};
  grid-template-columns: 1fr;
  margin: 116px 0 40px;
`;

export const NotFoundMainBox = styled(MainBox)`
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${minWidth('md', 'padding: 40px 24px;')};
`;

export const NotFoundHeadline = styled(Heading)`
  text-align: center;
  font-size: 23px;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  margin-bottom: ${gutterVariables.GUTTER_HALF};
  color: ${colorsVariables.GRAY_900};
  line-height: 33px;

  ${minWidth('md', 'font-size: 32px; line-height: 48px;')};
`;

export const NotFoundText = styled.div`
  white-space: pre-line;
  text-align: center;
  font-size: ${fontVariables.SIZE_TEXT};
  line-height: ${fontVariables.LINE_HEIGHT_L};
  color: ${colorsVariables.GRAY_900};
  margin-bottom: 40px;

  ${minWidth('md', 'font-size: 18px; line-height: 27px;')};
`;

export const CompanySearchLink = styled(LinkAsButton)`
  width: auto;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
`;
