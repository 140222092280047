import { css } from 'styled-components';
import { fontVariables } from '@trustedshops/tps-seo-shared-frontend-react-components';

export default css`
  input,
  .input {
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
  }
`;
